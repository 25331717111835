@import url('https://fonts.googleapis.com/css?family=Lato');

* {
	margin: 0;
	padding: 0;
	font-family: Lato, Calibri;
	box-sizing: border-box;
	text-indent: 0;
}

html,
body,
.root,
.page {
	height: 100%;
	background: #e8e8e8;
}

.content {
	min-height: 100%;
	background: #e8e8e8;
}

.content-inside {
	padding-bottom: 58px;
	text-align: center;
}

.root,
.page-switcher {
	height: 100%;
}

.footer {
	height: 32px;
	margin-top: -32px;
	clear: both;
	background: #222;
}

.header {
	position: relative;
	padding: 8px 12px;
	text-align: left;
	margin-bottom: 20px;
	background: #1e87f0;
	color: #fff;
}

.tf-form-labeled-number {
	margin-top: 8px;
}

.tf-form-labeled-number .label {
	display: inline-block;
	min-width: 182px;
	margin-right: 12px;
}

.tf-form-labeled-number .input {
	width: 100px;
}


.PanelPage {
	position: relative;
}
.PanelPage.body {
	position: relative;
	margin: 0;
	padding: 0;
	padding-bottom: 160px;
}

.PanelPage.body .server-id {
	position: absolute;
	right: 4px;
	bottom: -16px;
	font-size: 200px;
	line-height: 200px;
	color: #dcdcdc;
}

@media screen and (max-width: 660px) {

	.server-id {
		bottom: 0;
	}
}