@import "https://fonts.googleapis.com/css?family=Lato";
* {
  box-sizing: border-box;
  text-indent: 0;
  margin: 0;
  padding: 0;
  font-family: Lato, Calibri;
}

html, body, .root, .page {
  height: 100%;
  background: #e8e8e8;
}

.content {
  min-height: 100%;
  background: #e8e8e8;
}

.content-inside {
  text-align: center;
  padding-bottom: 58px;
}

.root, .page-switcher {
  height: 100%;
}

.footer {
  height: 32px;
  clear: both;
  background: #222;
  margin-top: -32px;
}

.header {
  text-align: left;
  color: #fff;
  background: #1e87f0;
  margin-bottom: 20px;
  padding: 8px 12px;
  position: relative;
}

.tf-form-labeled-number {
  margin-top: 8px;
}

.tf-form-labeled-number .label {
  min-width: 182px;
  margin-right: 12px;
  display: inline-block;
}

.tf-form-labeled-number .input {
  width: 100px;
}

.PanelPage {
  position: relative;
}

.PanelPage.body {
  margin: 0;
  padding: 0 0 160px;
  position: relative;
}

.PanelPage.body .server-id {
  color: #dcdcdc;
  font-size: 200px;
  line-height: 200px;
  position: absolute;
  bottom: -16px;
  right: 4px;
}

@media screen and (max-width: 660px) {
  .server-id {
    bottom: 0;
  }
}

/*# sourceMappingURL=index.b0724682.css.map */
